import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Residences from "../components/residences"
import Seo from "../components/seo"

const seo = {
  metaTitle: 'Syndicus',
}

const SyndicusPage = () => {
  const texts = useStaticQuery(graphql`
    query {
      strapiPagina {
        syndicus_intro
      }
    }
  `)

  return (
    <Layout active="syndicus" page="syndicus">
      <Seo seo={seo} />
      <Residences title="Syndicus" description={texts.strapiPagina.syndicus_intro} />
    </Layout>
  )
}

export default SyndicusPage
