import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby";
import Image from "./image";
 
const Residences = ({ title, description, items }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiResidence {
        edges {
          node {
            title
            location
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, height: 300)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section class="featured-section residences-section"> 
      <div class="rent-section">
        <header class="entry-header heading">
          <h2 class="entry-title">{title}</h2>
          {description && (<p style={{marginBottom: 0}} dangerouslySetInnerHTML={{__html: description}}></p>)}
        </header>
        <div class="container">
          <div class="row">
          {data.allStrapiResidence.edges.slice(0, items).map(item => item.node).map(item => (
            <div key={item.title + ' ' + item.location} className="custom-col-4" style={{ paddingBottom: 60 }}>
              <div class="post">
                <Image
                  className="post-featured-image"
                  image={item.image.localFile.childImageSharp.gatsbyImageData}
                  alt={`Afbeelding van ${item.title}`}
                  sizes={'(min-width: 1200px) 360px, (min-width: 768px) 33vw, 50vw'}
                />
                <header class="<entry-header">
                  <span class="location">{item.location}</span>
                  <h3 class="entry-title">{item.title}</h3>
                </header>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </section>
);
}

Residences.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  items: PropTypes.number.isRequired
}

Residences.defaultProps = {
  title: ``,
  description: undefined,
  items: 999
}

export default Residences
